/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

 */

import { useRef, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

//import { postRequest } from "../../../utils/common";
import useAPI from "../../../utils/useAPI";

import { useMaterialUIController, setUserRole } from "../../../context";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";


function Basic() {
  const navigate = useNavigate();
  const { getRequest,postRequest, putRequest , error } = useAPI();
  const [controller, dispatch] = useMaterialUIController();
  const { userRole } = controller;
  const emailRef = useRef(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [isOtpCode, setIsOtpCode] = useState(false);
  const [otpCode, setOtpCode] = useState({});
  const [emailError, setEmailError] = useState({
    status: false,
    message: "",
  });
  const [otpCodeError, setOtpCodeError] = useState({
    status: false,
    message: "",
    type: "",
  });
  const [userInfo, setUserInfo] = useState({
    email: "",
    otp: "",
  });

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async () => {
    try {
      const { email, otp } = userInfo;

      const result = await postRequest("authentication/login", {
        email,
        otp,
      });

      if (!result) throw new Error("Something went wrong");
      console.log("result:", result);


      if(result.status == 400){

        setTimeout(() => {
          setOtpCodeError({
            status: false,
            message: result?.message,
            type: "error",
          });
        }, 5000);


      }
      if (result.data.token) {

        const data = result.data;
        console.log(data);
        localStorage.setItem("authorization", data.token);

        setUserRole(dispatch, {
          role: data.role,
          email: data.email,
          active: data.active,
          expiredTime: data.expiredTime,
        });

        //window.location.href = "/dashboard";
        navigate("/dashboard");

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSentOTP = async () => {
    try {
      const { email } = userInfo;

      const data = await postRequest("generateOTP", {
        email,
      });

      if (!data) throw new Error("Something went wrong");

      if (data?.data) {
        setIsOtpCode(true);
        setEmailError({
          status: false,
          message: "",
        });
        setOtpCode(data);
        setOtpCodeError({
          status: true,
          message: data?.message,
          type: "success",
        });
      } else {
        emailRef.current.focus();

        setEmailError({
          status: true,
          message: data?.message,
        });

        setOtpCodeError({
          status: true,
          message: data?.message,
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setOtpCodeError({
          status: false,
          message: "",
          type: "",
        });
      }, 5000);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      {otpCodeError?.type && (
        <section
          style={{
            position: "absolute",
            top: 40,
            right: 10,
            width: 300,
            height: 100,
            color: "#fff",
            background: otpCodeError.type === "error" ? "#f44336" : "#4caf50",
            borderRadius: 6,
            border: "1px solid #fff",
            padding: "10px 20px",
            zIndex: 999,
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 700,
              borderBottom: "1px solid #d3d3d3",
            }}
          >
            {otpCodeError.type === "error" ? "Otp Code Error" : "Otp Code Sent"}
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              marginTop: 10,
            }}
          >
            {otpCodeError.message}
          </div>
        </section>
      )}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            {/*<Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>*/}
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                ref={emailRef}
                error={emailError.status}
                helperText={emailError.message}
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    email: e.target.value,
                  });
                }}
              />
            </MDBox>

            {isOtpCode && (
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Otp Code"
                  fullWidth
                  error={otpCodeError.status}
                  helperText={otpCodeError.message}
                  onChange={(e) => {
                    const value = e.target.value;
                    console.log(value, otpCode.otp);

                    if (value.length >= 6) {
                      setOtpCodeError({
                        status: false,
                        message: "",
                      });

                      setUserInfo({
                        ...userInfo,
                        otp: value,
                      });
                    } else {
                      setOtpCodeError({
                        status: true,
                        message: "Otp code is not valid",
                      });
                    }
                  }}
                />
              </MDBox>
            )}
            {/* <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    password: e.target.value,
                  });
                }}
              />
            </MDBox> */}
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            {!otpCode || !otpCode?.success ? (
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSentOTP}
                  disabled={!!otpCode.otp}
                >
                  Generate OTP
                </MDButton>
              </MDBox>
            ) : (
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSignIn}
                  disabled={otpCodeError.status}
                >
                  Sign In
                </MDButton>
              </MDBox>
            )}

            {/*<MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>*/}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
