const Preview = ({ data }) => {
  const { subject, name, body, type, status, id } = data;
  console.log(data, subject, body);
  // const [tag, setTag] = useState("");
  // const [isUse, setIsUse] = useState(false);

  // const handleChange = (event) => {
  //   event.preventDefault();

  //   const value = event.target.value;

  //   setTag(value);
  // };

  // const handleAddTag = async () => {
  //   try {
  //     setIsUse(true);
  //     const response = await fetch("/api/tags", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         tag,
  //         id,
  //       }),
  //     });

  //     const data = await response.json();

  //     console.log(data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsUse(false);
  //   }
  // };

  return (
    <section
      style={{
        padding: 12,
        height: 800,
        backgroundColor: "#fff",
        borderRadius: 6,
        border: "1px solid #d3d3d3",
        position: "relative",
      }}
    >
      {/* <h4>Add Tags</h4>
      <TextField
        id="add_tags"
        label="Add Tags"
        placeholder="Add Tags"
        sx={{
          width: 300,
        }}
        onChange={handleChange}
      /> */}

      <div
        style={{
          marginTop: 12,
        }}
      >
        <h4>Subject</h4>
        <p>{subject}</p>
      </div>

      <div
        style={{
          marginTop: 12,
          height: 550,
          overflowY: "auto",
        }}
      >
        <h4>Body</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        ></div>
      </div>

      {/* <footer
        style={{
          position: "absolute",
          bottom: 5,
        }}
      >
        <Button
          sx={{
            color: "#fff",
          }}
          variant="contained"
          onClick={handleAddTag}
          disabled={isUse}
        >
          Use
        </Button>
      </footer> */}
    </section>
  );
};

export default Preview;
