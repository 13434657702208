import React from "react";
import {
  Modal,
  Paper,
  Typography,
  Button,
  Divider,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DetailMessage = ({ open, onClose, messageData }) => {
  console.log("messageData:", messageData);

  const {
    email_from: from,
    email_to: to,
    email_subject: subject,
    email_body: bodyText,
    html: htmlText,
    mailgunId,
    createdAt: createdTime,
    updatedAt: updatedTime,
    events,
  } = messageData;

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        overflowY: "scroll",
      }}
    >
      <Paper sx={{ p: 2, maxWidth: "90%", margin: "auto", mt: "20px", mb: "20px" }}>
        <Typography variant="h6" sx={{ mb: 2, mx :4, my:2 }}>
          {subject}
        </Typography>
        <DialogContent sx={{ maxHeight: "90%" }}>
             <Accordion>
               <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography variant="h6" sx={{ mt: 3 }}>
                Detail Message
              </Typography>
              </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                <strong>From:</strong> {from}
              </Typography>
              <Typography variant="body1">
                <strong>To:</strong> {to}
              </Typography>
              <Typography variant="body1">
                <strong>Body Text:</strong> {bodyText}
              </Typography>
              <Typography variant="body1">
                <strong>Html Text:</strong>

                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlText,
                  }}
                  style={{
                    border: "1px solid #ccc",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                ></div>
              </Typography>
              <Typography variant="body1">
                <strong>Mailgun Id:</strong> {mailgunId}
              </Typography>
              <Typography variant="body1">
                <strong>Created Time:</strong> {createdTime}
              </Typography>
              <Typography variant="body1">
                <strong>Updated Time:</strong> {updatedTime}
              </Typography>
           </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
              <Typography variant="h6" sx={{ mt: 3 }}>
                Event Data
              </Typography>
            </AccordionSummary>
          <AccordionDetails>
              {events && Array.isArray(events) && (
                <ul>
                  {events.map((event) => (
                    <li key={event.id}>
                      <strong>Event:</strong> {event.event}
                      <br />
                      <strong>Timestamp:</strong> {event.timestamp}
                      <br />
                      {/* <strong>Message ID:</strong> {event.message_id}
                      <br />
                      <strong>From:</strong> {event.from || "N/A"}
                      <br />
                      <strong>To:</strong> {event.to || "N/A"}
                      <br /> */}
                      <strong>Created At:</strong> {event.createdAt}
                      <br />
                      <strong>Updated At:</strong> {event.updatedAt}
                      <br />
                      <Divider sx={{ my: 1 }} />
                    </li>
                  ))}
                </ul>
              )}
          </AccordionDetails>
        </Accordion>
        </DialogContent>
        <Divider sx={{ my: 2 }} />
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default React.memo(DetailMessage);
