// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Search, Edit, Shortcut, Remove } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Alert, Button, Snackbar } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import useAPI from "../../utils/useAPI";
import { formatDistanceToNow,format } from 'date-fns';

function SuppressionList() {
  const { getRequest,postRequest, putRequest , error } = useAPI();

  const [data, setData] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState(''); // email addresses to save
  const [recordId, setRecordId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);

  const [popup, setPopup] = useState({
      desc: "",
      severity: "success",
  });

  const handleClose = (_event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setPopup({
        desc: "",
        severity: "success",
      });
  };

  const columns = [
      {
        Headers: "Operations",
        accessor: "id",
        align: "left",
        width: 50, // Customize the width
        Cell: ({ row }) => {
          return (
          <Edit
             style={{
               cursor: "pointer",
             }}
             onClick={() => handleEditedById(row.original.id)}
          />
          );
        },
      },
     { Header: "Email Address", accessor: "emailAddress", align: "left" },
     { Header: "Created Time", accessor: "createdAt", align: "left" ,
         Cell: ({ value }) => (
             <p style={{ cursor: 'pointer' }} title={format(new Date(value),"Pp")}>{formatDistanceToNow(new Date(value), { addSuffix: true })}</p>
         )
    },
     { Header: "Updated Time", accessor: "updatedAt", align: "left" ,
         Cell: ({ value }) => (
             <p style={{ cursor: 'pointer' }} title={format(new Date(value),"Pp")}>{formatDistanceToNow(new Date(value), { addSuffix: true })}</p>
         )
     },
  ];

  const fetchData = async () => {
     try {
       const data = await getRequest("suppressionList");
       if (data) {
         console.log("suppression List fetched successfully!", data.data);
         setData(data.data);
         setPopup({
            desc: `Suppression List fetched successfully!`,
            severity: "success",
         });
       } else {
         console.error("Failed to fetch suppression List.");
         setPopup({
             desc: `Failed to fetch suppression List.`,
             severity: "Error",
         });
       }
     } catch (error) {
       console.error("An error occurred while fetching the suppression List:", error);
       setPopup({
         desc: "An error occurred while fetching the suppression List : " + error,
         severity: "error",
       });
     }
  };

   useEffect(() => {
      fetchData();
   }, []);

  const handleOnChange = (value) => {
    console.log('handleOnChange');
    console.log('value : ' + value);

    setEmailAddresses(value)

    if(value === ''){
        setRecordId('');
        setIsShowEdit(false);
        setIsUpdating(false);
    }
  };

  const handleEditedById = async (event) => {
     console.log('handleEditedById clicked.');
     setIsShowEdit(true);

     const selectedId = event;
     console.log('selectedId ->',selectedId);

     setRecordId(selectedId);

     const foundRecord = data.find(record => record.id === selectedId);
     console.log('foundRecord');
     console.log(foundRecord);

     setEmailAddresses(foundRecord.emailAddress);
  };

  const handleSave = async () => {
    console.log('handle Save invoked');

    console.log('recordId: ' + recordId);
    console.log('emailAddresses: ' + emailAddresses);

    let contentBody = {
      emailAddress: emailAddresses,
    };

    if (recordId) {
      contentBody.id = recordId;
    }

    console.log('contentBody:', contentBody);

    try {
      setIsSaving(recordId ? false : true);
      setIsUpdating(recordId ? true : false);

      const response = await fetch("/api/saveSuppressionList", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contentBody),
      });

      if (response.ok) {
        console.log(
          `suppressionList record ${recordId ? 'Updated' : 'created'} successfully!`
        );
         setPopup({
           desc: `Record ${recordId ? 'Updated' : 'created'} successfully!`,
           severity: "success",
         });
        setEmailAddresses('');
        fetchData();
      } else if (response.status === 400) {
        const errorMessage = await response.json();
        console.error('errorMessage ->', errorMessage.message);

        window.alert(errorMessage.message); // show an alert message when a duplicate email is found
      }
      setRecordId('');
      setIsShowEdit(false);
    } catch (err) {
      console.error("error");
      console.error(err);
    } finally {
      setIsSaving(false);
      setIsUpdating(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Suppression List
                </MDTypography>
              </MDBox>

              <MDBox pt={5}>
              <form>

                  <MDInput
                    variant="outlined"
                    label= {isShowEdit ? "Update email" : "Enter email" }
                    value={emailAddresses}
                    onChange={(e) => handleOnChange(e.target.value)}
                    sx={{
                        color: "#fff",
                        margin: "0 17px",
                    }}
                  />

                  <Button
                      sx={{
                        color: "#fff",
                        margin: "0 17px",
                      }}
                      variant="contained"
                      disabled={isSaving || isUpdating}
                      onClick={handleSave}
                  >
                   {/*onChange={(e) => setEmailAddresses(e.target.value)}*/}
                    {/*{isSaving ? "Saving" : "Save"}*/}
                    {isSaving ? "Saving" : (isShowEdit ? "Update" : "Save")}
                  </Button>
                </form>
                <DataTable
                  table={{ columns: columns, rows: data }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  selectedRowId={recordId}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!popup.desc && (
          <Alert
            onClose={handleClose}
            severity={popup.severity}
            sx={{ width: "100%" }}
          >
            {popup.desc}
          </Alert>
          )}
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default SuppressionList;
