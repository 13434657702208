import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  Button,
  Divider,
  DialogContent,
  DialogActions,
} from "@mui/material";

import useAPI from "../../utils/useAPI";

const PendingEmails = ({ open, onClose }) => {
    const { getRequest,postRequest, putRequest , error } = useAPI();
    const [pendingEmails, setPendingEmails] = useState([]);
    const [isStartEmailSend, setIsStartEmailSend] = useState(false);
    const [isDisplayStartEmailSend, setIsDisplayStartEmailSend] = useState(false);

    const fetchData = async () => {
      try {
        const data = await getRequest("pendingEmails"); // get all pending emails
        console.log("fetchData invoke");
        if (data) {
          console.log("Emails message count fetched successfully: ", data.data);

          setPendingEmails(data.data);
          if(data.data > 0){
              setIsDisplayStartEmailSend(true);
          }else if(data.data === 0){
              setIsDisplayStartEmailSend(false);
          }
        } else {
          console.error("Failed to fetch emails.");
        }

        const cronJobs = await getRequest("getCronJobs"); // get CronJobs status
        if (cronJobs.status == 200) {
          console.log('cronJobs with status 200');
          console.log('status : ' + cronJobs.status);
          console.log('message : ' + cronJobs.message);
          setIsStartEmailSend(true);
        } else if (cronJobs.status == 400) {
           console.log('cronJobs with status 400');
           console.log('status : ' + cronJobs.status);
           console.log('message : ' + cronJobs.message);
           setIsStartEmailSend(false);
        }
        else {
          console.error("Failed to fetch cronJobs.");
        }

      } catch (error) {
        console.error("An error occurred while fetching the emails:", error);
      }
    };

    useEffect(() => {
        fetchData(); // Initial fetch
        const interval = setInterval(fetchData, 10000); // Fetch every 10 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const handleStartSendEmail = async () => {
      console.log("Start Sending email:");

      try {
        const response = await fetch("/api/start-send-email-job", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        });

        if (response.ok) {
          console.log("Job started.");
          setIsStartEmailSend(true);
        } else {
          console.error("Job does not exist.");
        }
      } catch (error) {
        console.error("An error occurred while start sending the email:", error);
      }
    };

    const handleStopSendEmail = async () => {
      console.log("Stop Sending email:");

      try {
        const response = await fetch("/api/stop-send-email-job", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        });

        if (response.ok) {
          console.log("Job Stopped.");
          setIsStartEmailSend(false);
        } else {
          console.error("Job is not running or does not exist.");
        }
      } catch (error) {
        console.error("An error occurred while Stop sending the email:", error);
      }
    };


  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        overflow: "auto",
      }}
    >
      <Paper sx={{ p: 5,  margin: "auto" ,width : "90%" ,height: "90%"}}>
       <DialogContent>
            {!isStartEmailSend && isDisplayStartEmailSend && (
               <Button
                 sx={{
                   color: "#fff",
                   margin: "0 15px",
                 }}
                 variant="contained"
                 onClick={handleStartSendEmail}
               >
                  Start Email Send
               </Button>
            )}
            {isStartEmailSend && (
               <Button
                sx={{
                  color: "#fff",
                }}
                variant="contained"
                onClick={handleStopSendEmail}
               >
                 Stop Email Send
               </Button>
           )}
           <Typography variant="h6" sx={{ margin: "0 15px"}} pt={3}>
             Total Pending Emails  : {pendingEmails}
           </Typography>

       </DialogContent>
        <Divider sx={{ my: 40 }} />
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default PendingEmails;
