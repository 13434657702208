import MDInput from "components/MDInput";
import { CKEditor } from "ckeditor4-react";
import { editorConfiguration } from "../conf/config";
import { Button, TextField, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
//import { putRequest, postRequest } from "utils/common";
import useAPI from "utils/useAPI";



const tags = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Active",
    value: "Active",
  },
];

const EditedCK = ({ onPreview, onReturn, onSuccess, onError, data }) => {
    const { getRequest,postRequest, putRequest , error } = useAPI();

    const [template, setTemplate] = useState(null);
    const [editors, setEditors] = useState(null);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const { name, subject, body, type, status } = template;

      if (!template?.id) {
        await postRequest("/data/emailTemplates", {
          name,
          subject,
          body,
          type,
          status,
        });

        onSuccess({
          desc: "Successfully created",
          severity: "success",
        });
      } else {
        await putRequest(`/data/emailTemplates/${template.id}`, {
          ...template,
          name,
          subject,
          body,
        });

        onSuccess({
          desc: "Successfully updated",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      onError({
        desc: "Failed to update",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    data && setTemplate(data);

    if (editors) {
      const innerData = editors.getData();
      if (!innerData) {
        editors.setData(data?.body);
      }
    }
  }, [editors, data]);

  return (
    <section
      style={{
        backgroundColor: "#fff",
        padding: 12,
        marginTop: 12,
        borderRadius: 6,
        border: "1px solid #d3d3d3",
      }}
    >
      <form onSubmit={handleUpdate}>
        <div>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Template Name
          </h3>
          <MDInput
            fullWidth
            autoFocus
            variant="outlined"
            placeholder="Template name"
            value={template?.name}
            onChange={(event) => {
              setTemplate((template) => {
                return {
                  ...template,
                  name: event.target.value,
                };
              });

              onPreview({
                ...template,
                name: event.target.value,
              });
            }}
          />
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Type
          </h3>
          <MDInput
            fullWidth
            variant="outlined"
            placeholder="Type"
            value={template?.type}
            onChange={(event) => {
              setTemplate((template) => {
                return {
                  ...template,
                  type: event.target.value,
                };
              });

              onPreview({
                ...template,
                type: event.target.value,
              });
            }}
          />
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Status
          </h3>
          <TextField
            id="select_status"
            select
            label="Select Status"
            placeholder="Select Status"
            sx={{
              m: 1,
              width: "28%",
              height: 45,
              marginRight: 5,
              "& .MuiOutlinedInput-root": {
                height: "100%",
              },
            }}
            onChange={(event) => {
              setTemplate((template) => {
                return {
                  ...template,
                  status: event.target.value,
                };
              });

              onPreview({
                ...template,
                status: event.target.value,
              });
            }}
          >
            {tags.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Subject
          </h3>
          <MDInput
            fullWidth
            variant="outlined"
            placeholder="Subject"
            value={template?.subject}
            onChange={(event) => {
              setTemplate((template) => {
                return {
                  ...template,
                  subject: event.target.value,
                };
              });

              onPreview({
                ...template,
                subject: event.target.value,
              });
            }}
          />

          <h3
            style={{
              fontSize: 16,
            }}
          >
            Body
          </h3>
          <CKEditor
            config={editorConfiguration}
            initData={template?.body}
            data={template?.body}
            onChange={({ editor }) => {
              const body = editor.getData();

              setTemplate((temp) => {
                return {
                  ...data,
                  ...temp,
                  body,
                };
              });

              onPreview({
                ...template,
                body,
              });
            }}
            onInstanceReady={({ editor }) => {
              setEditors(editor);
            }}
          />
        </div>
        <footer
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 12,
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "#000",
              border: "1px solid #d3d3d3",
              marginRight: 6,
            }}
            onClick={() => {
              onPreview(template);
            }}
          >
            Preview
          </Button>
          <Button
            sx={{
              color: "#fff",
              marginRight : 6
            }}
            variant="contained"
            type="submit"
          >
            {template && template?.id ? "Update" : "Create"}
          </Button>

          <Button
            sx={{
              color: "#fff",
            }}
            variant="contained"
            onClick={() => {
              onReturn();
            }}
          >
            Back
          </Button>

          {/* <Button
            variant="text"
            onClick={() => {
              onReturn();
            }}
          >
            <Clear color="#d3d3d3" /> Return to Case
          </Button> */}
        </footer>
      </form>
    </section>
  );
};

export default EditedCK;
