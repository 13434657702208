/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Alert, Button, Snackbar } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
//import { getRequest } from "../../utils/common";
import useAPI from "../../utils/useAPI";

import DetailMessage from "./DetailMessage";
import PendingEmails from "./PendingEmails";
import { formatDistanceToNow } from 'date-fns';

function Messages() {
  const { getRequest,postRequest, putRequest , error } = useAPI();
  const [data1, setData1] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPendingEmail, setOpenPendingEmail] = useState(false);
  const [messageData, setMessageData] = useState({
    from: "",
    to: "",
    subject: "",
    bodyText: "",
    htmlText: "",
    mailgunId: "",
    createdTime: "",
    updatedTime: "",
  });

  const [popup, setPopup] = useState({
      desc: "",
      severity: "success",
  });

  const handlePopUpClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPopup({
      desc: "",
      severity: "success",
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPendingEmail = () => {
      setOpenPendingEmail(true);
    };

    const handleClosePendingEmail = () => {
      setOpenPendingEmail(false);
    };

  const queryDetailMessageById = async (rowData) => {
    try {
      const messageId = rowData.id;
      const data = await getRequest(`data/emailMessage/${messageId}`);
      if (data) {
        console.log("EmailMessage fetched successfully!", data.data);
        setMessageData(data.data);
      } else {
        console.error("Failed to fetch Email Messages.");
        setPopup({
          desc: "Failed to fetch Email Message",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred while fetching the email:", error);
      setPopup({
        desc: "An error occurred while fetching the Email message : " + error,
        severity: "error",
      });
    } finally {
      handleOpen();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRequest("data/emailMessages");
        if (data) {
          console.log("Emails fetched successfully!", data.data);
          setData1(data.data);
          setPopup({
            desc: "Email Messages fetched successfully!",
            severity: "success",
          });
        } else {
          console.error("Failed to fetch emails.");
          setPopup({
            desc: "Failed to fetch Email Messages",
            severity: "error",
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching the emails:", error);
        setPopup({
          desc: "An error occurred while fetching the Email message : " + error,
          severity: "error",
        });
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      Headers: "Operations",
      accessor: "operations",
      align: "center",
      Cell: (props) => {
        return (
            <RemoveRedEye
                style={{
                  cursor: "pointer",
                }}
                onClick={async () => {
                  await queryDetailMessageById(props.row.original);
                }}
            />
        );
      },
    },
    { Header: "Subject", accessor: "email_subject" },
    { Header: "From", accessor: "email_from"},
    { Header: "To", accessor: "email_to" },
    { Header: "Status", accessor: "status", align: "left" },
  /*  { Header: "Body Text", accessor: "email_body", align: "left" },
    { Header: "Html Text", accessor: "html", align: "left" },
    { Header: "Mailgun Id", accessor: "mailgun_id", align: "left" },*/
    { Header: "Created Time", accessor: "createdAt", align: "left" ,
        Cell: ({ value }) => (
           <span>{formatDistanceToNow(new Date(value), { addSuffix: true })}</span>
        )
    },
    { Header: "Updated Time", accessor: "updatedAt", align: "left" ,
        Cell: ({ value }) => (
           <span>{formatDistanceToNow(new Date(value), { addSuffix: true })}</span>
        )
    },

  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Email Messages
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>

              <Button
                  sx={{
                    color: "#fff",
                    margin: "0 17px",
                  }}
                  variant="contained"
                  onClick={handleOpenPendingEmail}
              >
              Send Pending Email
             </Button>


                <DataTable
                  table={{ columns: columns, rows: data1 }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {open && (
          <DetailMessage
            open={open}
            onClose={handleClose}
            messageData={messageData}
          />
        )}
        {openPendingEmail && (
          <PendingEmails
            open={openPendingEmail}
            onClose={handleClosePendingEmail}
          />
        )}
      </MDBox>
      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handlePopUpClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!popup.desc && (
          <Alert
            onClose={handlePopUpClose}
            severity={popup.severity}
            sx={{ width: "100%" }}
          >
            {popup.desc}
          </Alert>
        )}
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default Messages;
