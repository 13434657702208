import React, { createContext, useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  // const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
      true
   // localStorage.getItem("authorization") !== null
  );

  const login = (token) => {
    localStorage.setItem("authorization", token);
    setIsAuthenticated(true);
    // navigate("/dashboard");
  };

  const logout = () => {
    localStorage.removeItem("authorization");
    setIsAuthenticated(false);
    // navigate("/authentication/sign-in");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
