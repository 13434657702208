import { useEffect, useRef } from "react";
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";

const ModalComponent = ({
  onClose,
  title = "modal title",
  footer = true,
  width = 1000,
  height = 600,
  open,
  children,
  onSubmit = () => {},
  submitText = "Submit",
}) => {
  const modalRef = useRef(null);

  const modalStyle = {
    position: "fixed",
    display: open ? "flex" : "none",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    transition: ".5s all",
    width: width,
    minHeight: height,
    backgroundColor: "#fff",
    borderRadius: 6,
    outline: "1px solid #d3d3d366",
    zIndex: 100,
    boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
    flexDirection: "column",
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 8,
    borderBottom: "1px solid #d3d3d355",
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose(event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const handleClose = (event) => {
    event.stopPropagation();

    onClose();
  };

  const handleSubmit = () => {
    onSubmit();

    onClose();
  };

  return (
    <div ref={modalRef} id="modal-root" style={modalStyle}>
      <header style={headerStyle}>
        <h3
          style={{
            paddingLeft: 10,
            textTransform: "capitalize",
          }}
        >
          {title}
        </h3>
        <Close
          style={{
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </header>
      <section
        style={{
          position: "relative",
          padding: "0px 20px",
          width: "100%",
          flex: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {children}
      </section>
      {footer && (
        <footer
          style={{
            borderTop: "1px solid #d3d3d355",
            width: "100%",
            height: 50,
            bottom: 0,
            marginTop: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            backgroundColor: "#f6f6f688",
          }}
        >
          <Button
            sx={{
              padding: "8px 16px",
              color: "#fff",
            }}
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            {submitText}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default ModalComponent;
