/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import React, { useEffect, useState } from "react";
import useAPI from "../../utils/useAPI";
import Pagination from "@mui/material/Pagination";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { InputLabel } from "@mui/material";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
//import projectsTableData from "layouts/tables/data/projectsTableData";

function Tables() {
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageCount: 10,
    pages: 10,
  });
  const { getRequest, postRequest, putRequest, error } = useAPI();
  // const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();

  const [page, setPage] = React.useState(1);
  const [columns, setColumns] = useState([
    { Header: "Id", accessor: "id", align: "center" },
    { Header: "From", accessor: "from", align: "center" },
    { Header: "To", accessor: "to", align: "center" },
    { Header: "Message Id", accessor: "msgId", align: "center" },
    { Header: "Email Message Id", accessor: "emailMsgId", align: "center" },
    { Header: "Status", accessor: "messageStatus", align: "center" },
    { Header: "Type", accessor: "Type", align: "center" },
    { Header: "Created Date", accessor: "createdAt", align: "center" },
  ]);

  const [data1, setData1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = ["sms", "email"]; // Dropdown type options
  const [typeValue, setTypeValue] = useState("");

  const statusOptions = ["accepted", "delivered", "Opened"]; // Dropdown status options
  const [statusValue, setStatusValue] = useState("");

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  function typeFilter(val) {
    setTypeValue(val);
    console.log("typeValue : " + val);
  }

  function statusFilter(val) {
    setStatusValue(val);
    console.log("setStatusFilter : " + val);
  }

  function fromFilter(val) {
    setFromValue(val);
    console.log("fromValue : " + fromValue);
  }

  function toFilter(val) {
    setToValue(val);
    console.log("toValue : " + toValue);
  }

  const fetchData = async (value, pageSize = pagination.pageCount) => {
    try {
      setIsLoading(true);
      //let message_type = "email";
      let message_type = typeValue;

      let page_no = page;

      if (value !== undefined) {
        page_no = value;
      }

      /*if (
        message_type !== "sms" &&
        message_type !== "email" &&
        message_type !== ""
      ) {
        message_type = "";
        console.log("replace message_type null and undefined by empty string");
      }*/

      console.log("page_no : " + page_no);
      console.log("value : " + value);
      console.log("message_type : " + message_type);

      const data = await getRequest(
        "allEvents?pageNo=" +
          page_no +
          `&pageSize=${pageSize}&type=` +
          message_type +
          `&status=${statusValue}&from=${fromValue}&to=${toValue}`
      );
      if (data) {
        console.log(
          "events fetched successfully!",
          data.data.totalRecords,
          data.data.results
        );
        const totalRecords = data.data.totalRecords;

        setData1(data.data.results);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: Math.ceil(totalRecords / prevPagination.pageCount) || 10,
        }));

        // this code dynamically adjusts the columns array by removing columns based on the presence of certain fields in the fetched data.
        if (message_type) {
          console.log("type has value");
          if (data.data.results.some((item) => item.msgId)) {
            setColumns((prevColumns) =>
              prevColumns.filter((column) => column.accessor !== "emailMsgId")
            );
          }
          if (data.data.results.some((item) => item.emailMsgId)) {
            setColumns((prevColumns) =>
              prevColumns.filter((column) => column.accessor !== "msgId")
            );
          }
        }
      } else {
        console.error("Failed to fetch events.");
      }
    } catch (error) {
      console.error("An error occurred while fetching the events:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [typeValue, statusValue, fromValue, toValue]);

  const handleChange = (event, value) => {
    console.log("page no : " + value);

    setPage(value);
    fetchData(value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Events
                </MDTypography>
              </MDBox>

              <MDBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MDBox display={"flex"} alignItems={"center"} gap={2}>
                      <InputLabel
                        style={{
                          overflow: "visible",
                        }}
                        htmlFor="type-autocomplete"
                      >
                        Type
                      </InputLabel>
                      <Autocomplete
                        id="type-autocomplete"
                        disableClearable
                        options={typeOptions}
                        value={typeValue || ""}
                        onChange={(event, newValue) => {
                          typeFilter(newValue);
                        }}
                        size="small"
                        sx={{ width: "6rem" }}
                        renderInput={(params) => (
                          <MDInput {...params} placeholder="Type" />
                        )}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={6}>
                    <MDBox
                      width="10rem"
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <InputLabel
                        style={{
                          overflow: "visible",
                        }}
                        htmlFor="from-autocomplete"
                      >
                        From
                      </InputLabel>
                      <MDInput
                        label="From"
                        id="from"
                        placeholder="From"
                        value={fromValue}
                        onChange={({ currentTarget }) => {
                          fromFilter(currentTarget.value);
                        }}
                        size="small"
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox display={"flex"} alignItems={"center"} gap={2}>
                      <InputLabel
                        style={{
                          overflow: "visible",
                        }}
                        htmlFor="status-autocomplete"
                      >
                        Status
                      </InputLabel>
                      <Autocomplete
                        id="status-autocomplete"
                        disableClearable
                        options={statusOptions}
                        value={statusValue || ""}
                        onChange={(event, newValue) => {
                          statusFilter(newValue);
                        }}
                        size="small"
                        sx={{ width: "8rem" }}
                        renderInput={(params) => (
                          <MDInput {...params} placeholder="Status" />
                        )}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox
                      width="10rem"
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <InputLabel
                        style={{
                          overflow: "visible",
                        }}
                        htmlFor="to-autocomplete"
                      >
                        To
                      </InputLabel>
                      <MDInput
                        label="To"
                        id="to"
                        placeholder="To"
                        value={toValue}
                        onChange={({ currentTarget }) => {
                          toFilter(currentTarget.value);
                        }}
                        size="small"
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={1}>
                <DataTable
                  isLoading={isLoading}
                  table={{ columns: columns, rows: data1 }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  paginationChange={(pageCount) => {
                    console.log("page count : " + pageCount);
                    setPagination((prevPagination) => ({
                      ...prevPagination,
                      pageCount: pageCount,
                    }));

                    fetchData(1, pageCount);
                  }}
                />
              </MDBox>
              <MDBox>
                <Pagination
                  count={pagination.pages} // Total number of pages
                  page={page} // Current active page
                  onChange={handleChange}
                  variant="outlined"
                  color="primary"
                />
                <MDBox pt={3} />
              </MDBox>
            </Card>
          </Grid>

          {/*<Grid item xs={12} display={'none'}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: columns, rows: data1 }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>*/}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
