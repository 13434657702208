export const tableHeaders = [
  {
    title: "TO",
    key: "to_number",
    dataIndex: "to_number",
  },
  {
    title: "From",
    key: "from_number",
    dataIndex: "from_number",
  },
  {
    title: "Text Body",
    key: "text_body",
    dataIndex: "text_body",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text) => {
      return !!text ? text : "--";
    },
  },
  {
    title: "Direction",
    key: "direction",
    dataIndex: "direction",
    render: (text) => {
      return !!text ? text : "--";
    },
  },
];
