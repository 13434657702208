import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
  Typography,
  MenuItem,
} from "@mui/material";
import { Edit, Remove, Pageview } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableComponent from "./components/TableComponent";
import ModalComponent from "./components/ModalComponent";
import { tableHeaders } from "./conf/config";
import DataTable from "examples/Tables/DataTable";
import { formatDistanceToNow,format } from 'date-fns';

/*
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/common";
*/


import useAPI from "utils/useAPI";


import { useMaterialUIController } from "context";


const status = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Guest",
    value: "guest",
  },
];

function UsersTab() {
    const { getRequest,postRequest, putRequest ,deleteRequest,  error } = useAPI();

    const [controller, _dispatch] = useMaterialUIController();
  const { userRole } = controller;
  const [users, setUsers] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowPwd, setIsShowPwd] = useState(false);
  const [isRemoveUser, setIsRemoveUser] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});
  const [userInfos, setUserInfos] = useState({
    id: "",
    email: "",
    active: "",
    password: "",
    role: "",
    firstName: "",
    lastName: "",
  });

  const [popup, setPopup] = useState({
    desc: "",
    severity: "",
  });

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPopup({
      desc: "",
      severity: "success",
    });
  };

  const queryUsers = async () => {
    try {
      const infos = await getRequest(`data/users`);

      const { data } = infos;

      setUsers(data);

      setPopup({
        desc: "User List fetched successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      setPopup({
        desc: "Failed to fetch User list",
        severity: "error",
      });
    }
  };

  const queryUserInfos = async (id) => {
    try {
      const infos = await getRequest(`data/users/${id}`);

      setUserInfos({
        ...infos.data,
      });

      setPopup({
        desc: "Preview infos fetched successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);

      setPopup({
        desc: "Failed to fetch preview infos",
        severity: "error",
      });
    }
  };

  const handleConfirmRemove = (record) => {
     console.log('record');
     console.log(record);
     setIsRemoveUser(true);
     setUserToRemove(record);
  }

  const handleRemove = async (record) => {
    try {
      if (!record.id) {
        throw new Error("Invalid record id");
      }
       console.log('removing user.. : ' + record.id);
      await deleteRequest(`data/users/${record.id}`);

      setPopup({
        desc: "Removed successfully",
        severity: "success",
      });
    } catch (error) {
      console.error(error);

      setPopup({
        desc: "Failed to Remove",
        severity: "error",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!userInfos?.id) {
        await postRequest("data/users", {
          ...userInfos,
        });

        setPopup({
          desc: "Successfully created",
          severity: "success",
        });
      } else {
        await putRequest(`data/users/${userInfos.id}`, {
          ...userInfos,
        });

        setPopup({
          desc: "Successfully updated",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      setPopup({
        desc: "Failed to create or update",
        severity: "error",
      });
    } finally {
      setUserInfos({
        id: "",
        email: "",
        active: "",
        password: "",
        role: "",
        firstName: "",
        lastName: "",
      });
    }
  };

  const handleEdited = async (record) => {
    setIsShowModal(true);

    await queryUserInfos(record.id);
  };

  useEffect(() => {
    queryUsers();
  }, []);

  /*const dataConf = [
    ...tableHeaders,
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_text, record) => {
        return (
          <div>
          {*//*onClick={() => handleRemove(record)}*//*}
            <Button
              sx={{
                minWidth: 40,
                width: 40,
                height: 40,
                color: "#fff",
                marginRight: 2,
                "& > span": {
                  width: 40,
                },
              }}
              variant="contained"
              type="submit"
              onClick={() => handleConfirmRemove(record)}
            >
              <Remove />
            </Button>

            <Button
              sx={{
                minWidth: 40,
                width: 40,
                height: 40,
                color: "#fff",
                marginRight: 2,
                "& > span": {
                  width: 40,
                },
              }}
              variant="contained"
              type="submit"
              onClick={() => handleEdited(record)}
            >
              <Pageview />
            </Button>
          </div>
        );
      },
    },
  ];*/


  const columns = [
       { Header: "Email", accessor: "email" },
       { Header: "First Name", accessor: "firstName" },
       { Header: "Last Name", accessor: "lastName" },
       { Header: "Role", accessor: "role"},
       { Header: "Active", accessor: "active" ,
         Cell: (text) => {
            return !!text ? "Active" : "Inactive";
         }
       },
       { Header: "Created Time", accessor: "createdAt",
           Cell: ({ value }) => (
      <p style={{ cursor: 'pointer' }} title={format(new Date(value),"Pp")}>{formatDistanceToNow(new Date(value), { addSuffix: true })}</p>

)
      },
       { Header: "Updated Time", accessor: "updatedAt",
           Cell: ({ value }) => (
      <p style={{ cursor: 'pointer' }} title={format(new Date(value),"Pp")}>{formatDistanceToNow(new Date(value), { addSuffix: true })}</p>
           )
       },
       {
         Header: "Action",
         accessor: "id",
         align: "center",
         Cell: ({ row }) => {
           return (
             <div>
             {/*onClick={() => handleRemove(record)}*/}
               <Button
                 sx={{
                   minWidth: 40,
                   width: 40,
                   height: 40,
                   color: "#fff",
                   marginRight: 2,
                   "& > span": {
                     width: 40,
                   },
                 }}
                 variant="contained"
                 type="submit"
                 onClick={() => handleConfirmRemove(row.original)}
               >
                 <Remove />
               </Button>

               <Button
                 sx={{
                   minWidth: 40,
                   width: 40,
                   height: 40,
                   color: "#fff",
                   marginRight: 2,
                   "& > span": {
                     width: 40,
                   },
                 }}
                 variant="contained"
                 type="submit"
                 onClick={() => handleEdited(row.original)}
               >
                 <Pageview />
               </Button>
             </div>
           );
         },
       },
    ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid pt={6} pb={3} container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography
              variant="h6"
              color="#fff"
              display={"flex"}
              justifyContent={"center"}
              sx={{
                fontSize: 22,
              }}
            >
              Users Tab
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid item xs={7} marginBottom={2}>
          <Button
            sx={{
              Width: 100,
              height: 40,
              color: "#fff",
              "& > span": {
                width: 40,
              },
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              setUserInfos({
                id: "",
                email: "",
                active: "",
                password: "",
                role: "",
                firstName: "",
                lastName: "",
              });
              setIsShowModal(true);
            }}
          >
           {/* <Edit />*/}
            Add User
          </Button>
        </Grid>

        {/*<TableComponent headers={dataConf} data={users} />*/}

        <DataTable
          table={{ columns: columns, rows: users }}
          isSorted={true}
          canSearch={true}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </Grid>

      <ModalComponent
        open={isRemoveUser}
        onClose={() => setIsRemoveUser(false)}
        onSubmit={() => handleRemove(userToRemove)}
        submitText="Remove"
        title="Remove User"
        width={600}
        height={150}
      >
        Are you sure to remove this user from the list?
      </ModalComponent>

      <ModalComponent
        open={isShowModal}
        onClose={() => setIsShowModal(false)}
        onSubmit={handleSubmit}
        submitText="Save"
        title="User"
      >
        <Grid container spacing={2} paddingTop={4}>
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                marginLeft: 4,
              }}
            >
              <Typography>Email</Typography>
              <TextField
                label="email"
                value={userInfos.email}
                onChange={(e) =>
                  setUserInfos((values) => ({
                    ...values,
                    email: e.target.value,
                  }))
                }
              />
              <Typography>Password:</Typography>
              <TextField
                type={isShowPwd ? "text" : "password"}
                label="password"
                value={userInfos.password}
                onChange={(e) =>
                  setUserInfos((values) => ({
                    ...values,
                    password: e.target.value,
                  }))
                }
                helperText={
                  <p
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsShowPwd(!isShowPwd);
                    }}
                  >
                    {!isShowPwd ? "Show Password" : "Hide Password"}
                  </p>
                }
              />

              <Typography>First Name:</Typography>
              <TextField
                label="firstName"
                value={userInfos.firstName}
                onChange={(e) =>
                  setUserInfos((values) => ({
                    ...values,
                    firstName: e.target.value,
                  }))
                }
              />

              <Typography>Last Name:</Typography>
              <TextField
                label="lastName"
                value={userInfos.lastName}
                onChange={(e) =>
                  setUserInfos((values) => ({
                    ...values,
                    lastName: e.target.value,
                  }))
                }
              />

              <Typography>Last Name:</Typography>
              <TextField
                label="lastName"
                value={userInfos.lastName}
                onChange={(e) =>
                  setUserInfos((values) => ({
                    ...values,
                    lastName: e.target.value,
                  }))
                }
              />

              {userRole.role === "admin" && (
                <>
                  <Typography>Status:</Typography>
                  <TextField
                    id="select_status"
                    select
                    label="Select Status"
                    placeholder="Select Status"
                    sx={{
                      height: 45,
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                      },
                    }}
                    onChange={(event) => {
                      setUserInfos((_data) => ({
                        ..._data,
                        active: event.target.value,
                      }));
                    }}
                  >
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Typography>Role:</Typography>
                  <TextField
                    id="select_role"
                    select
                    label="Select Role"
                    placeholder="Select Role"
                    sx={{
                      height: 45,
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                      },
                    }}
                    onChange={(event) => {
                      setUserInfos((_data) => ({
                        ..._data,
                        role: event.target.value,
                      }));
                    }}
                  >
                    {roles.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </FormControl>
          </form>
        </Grid>
      </ModalComponent>

      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!popup.desc && (
          <Alert
            onClose={handleClose}
            severity={popup.severity}
            sx={{ width: "100%" }}
          >
            {popup.desc}
          </Alert>
        )}
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersTab;
