import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Search, Edit, Shortcut, Remove } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableComponent from "./components/TableComponent";
import { tableHeaders } from "./conf/config";
import Preview from "./components/preview";
import EditedCK from "./components/EditedCK";
//import { getRequest, deleteRequest } from "utils/common";
import useAPI from "utils/useAPI";


const tags = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Active",
    value: "Active",
  },
];

function EmailTemplates() {

  const { getRequest,postRequest, putRequest, deleteRequest , error } = useAPI();

  const [searchValue, setSearchValue] = useState({
    name: "",
  });
  const [showSearch, setShowSearch] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [previewInfos, setPreviewInfos] = useState(null);

  const [popup, setPopup] = useState({
    desc: "",
    severity: "",
  });

  const queryTempById = async (id) => {
    try {
      const infos = await getRequest(`/data/emailTemplates/${id}`);

      setPreviewInfos({
        ...infos.data,
      });

      setPopup({
        desc: "Preview infos fetched successfully",
        severity: "success",
      });

      setIsShowPreview(true);
    } catch (error) {
      console.log(error);
      setIsShowPreview(false);
      setPopup({
        desc: "Failed to fetch preview infos",
        severity: "error",
      });
    }
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPopup({
      desc: "",
      severity: "success",
    });
  };

  const queryTempLists = async () => {
    try {
      const infos = await getRequest(`/data/emailTemplates`);

      const { data } = infos;

      setTemplateList(data);
      setShowSearch(data);

      setPopup({
        desc: "TemplateList fetched successfully",
        severity: "success",
      });

      setIsShowPreview(false);
    } catch (error) {
      console.log(error);
      setIsShowPreview(false);
      setPopup({
        desc: "Failed to fetch preview infos",
        severity: "error",
      });
    }
  };

  const queryTempListByName = async (name) => {
    try {
      const infos = await getRequest(`/data/emailTemplates/search/${name}`);

      const { data } = infos;

      setTemplateList(data);

      setPopup({
        desc: "TemplateList fetched successfully",
        severity: "success",
      });

      setIsShowPreview(false);
    } catch (error) {
      console.log(error);
      setIsShowPreview(false);
      setPopup({
        desc: "Failed to fetch preview infos",
        severity: "error",
      });
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    const { name } = searchValue;

    await queryTempListByName(name);
  };

  const handleEditedById = async (record) => {
    setIsShowEdit(true);
    await queryTempById(record.id);
  };

  const handleRemoveById = async (record) => {
    try {
      await deleteRequest(`/data/emailTemplates/${record.id}`);

      setPopup({
        desc: "Remove successfully",
        severity: "success",
      });

      setIsShowPreview(false);
      setIsShowEdit(false);
    } catch (error) {
      console.log(error);
      setPopup({
        desc: "Failed to remove",
        severity: "error",
      });
    } finally {
      await queryTempLists();
    }
  };

  useEffect(() => {
    queryTempLists();
  }, []);

  const dataConf = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Remove
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleRemoveById(record)}
          />
          <Edit
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleEditedById(record)}
          />
          <Shortcut
            style={{
              cursor: "pointer",
            }}
            onClick={() => queryTempById(record["id"])}
          />
          <span
            style={{
              color: "#1A73E8",
              cursor: "pointer",
            }}
            onClick={() => queryTempById(record["id"])}
          >
            {text}
          </span>
        </span>
      ),
    },
    ...tableHeaders,
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid pt={6} pb={3} container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography
              variant="h6"
              color="#fff"
              display={"flex"}
              justifyContent={"center"}
              sx={{
                fontSize: 22,
              }}
            >
              Email Templates
            </MDTypography>
          </MDBox>
        </Grid>
        {isShowEdit ? null : (
          <Grid item xs={7}>
            <form>
              <FormControl
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  id="search"
                  label="Search"
                  autoFocus
                  placeholder="Search Name"
                  sx={{
                    m: 1,
                    width: "45%",
                  }}
                  onChange={(event) => {
                    setSearchValue({
                      ...searchValue,
                      name: event.target.value,
                    });
                  }}
                />

                <TextField
                  id="select_status"
                  select
                  label="Select Status"
                  placeholder="Select Status"
                  sx={{
                    m: 1,
                    width: "28%",
                    height: 45,
                    marginRight: 5,
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                    },
                  }}
                  onChange={(event) => {
                    if (event.target.value !== "")
                      setTemplateList((_data) =>
                        showSearch.filter(
                          (item) => item.status === event.target.value
                        )
                      );
                    else setTemplateList(showSearch);
                  }}
                >
                  {tags.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Button
                  sx={{
                    minWidth: 40,
                    width: 40,
                    height: 40,
                    color: "#fff",
                    marginRight: 2,
                    "& > span": {
                      width: 40,
                    },
                  }}
                  variant="contained"
                  type="submit"
                  onClick={handleSearch}
                >
                  <Search />
                </Button>

                <Button
                  sx={{
                    minWidth: 40,
                    width: 40,
                    height: 40,
                    color: "#fff",
                    "& > span": {
                      width: 40,
                    },
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsShowEdit(true);
                    setPreviewInfos({});
                  }}
                >
                  <Edit />
                </Button>
              </FormControl>
            </form>
          </Grid>
        )}

        <Grid
          item
          xs={isShowPreview ? 7 : 12}
          sx={{
            marginTop: -2,
          }}
        >
          {isShowEdit ? (
            <EditedCK
              onPreview={(values) => {
                setPreviewInfos((info) => ({
                  ...info,
                  ...values,
                }));
                setIsShowPreview(true);
              }}
              onReturn={() => {
                setIsShowEdit(false);
                setIsShowPreview(false);
                setPreviewInfos({});
                queryTempLists();
              }}
              onSuccess={(values) => {
                setPopup(values);
                setIsShowEdit(false);
                setIsShowPreview(false);

                queryTempLists();
              }}
              onError={(values) => {
                setPopup(values);
              }}
              data={previewInfos}
            />
          ) : (
            <TableComponent headers={dataConf} data={templateList} />
          )}
        </Grid>
        <Grid item xs={isShowPreview ? 5 : 0}>
          {isShowPreview && <Preview data={previewInfos} />}
        </Grid>
      </Grid>

      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!popup.desc && (
          <Alert
            onClose={handleClose}
            severity={popup.severity}
            sx={{ width: "100%" }}
          >
            {popup.desc}
          </Alert>
        )}
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailTemplates;
