export const tb = [
  {
    name: "document",
    items: ["Source", "-", "Print", "-", "Templates"],
  },
  {
    name: "clipboard",
    items: [
      "Preview",
      "Copy",
      "Cut",
      "Paste",
      "PasteText",
      "PasteFromWord",
      "-",
      "Undo",
      "Redo",
    ],
  },
  {
    name: "editing",
    items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
  },
  { name: "links", items: ["Link", "Unlink", "Anchor"] },

  "/",
  {
    name: "paragraph",
    items: [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
      "CreateDiv",
      "-",
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "BidiLtr",
      "BidiRtl",
    ],
  },
  {
    name: "basicstyles",
    items: [
      "Bold",
      "Italic",
      "Underline",
      "Strike",
      "Subscript",
      "Superscript",
      "-",
      "CopyFormatting",
      "RemoveFormat",
    ],
  },
  { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
  { name: "colors", items: ["TextColor", "BGColor"] },
  "/",
  { name: "insert", items: ["Image", "Table", "EmojiPanel", "SpecialChar"] },
  { name: "tools", items: ["Maximize", "ShowBlocks"] },
  { name: "about", items: ["About"] },
];

export const extPlugin = "emoji,autolink,uploadimage,colorbutton";

export const editorConfiguration = {
  toolbar: tb,
  extraPlugins: extPlugin,
};

export const tableHeaders = [
  {
    title: "SUBJECT",
    key: "subject",
    dataIndex: "subject",
  },
  {
    title: "TYPE",
    key: "type",
    dataIndex: "type",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
];
