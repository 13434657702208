import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Search, Edit, Pageview } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableComponent from "./components/TableComponent";
import ModalComponent from "./components/ModalComponent";
import { tableHeaders } from "./conf/config";
//import { getRequest, postRequest, putRequest } from "utils/common";
import useAPI from "utils/useAPI";
import { formatDistanceToNow } from 'date-fns';
import DataTable from "examples/Tables/DataTable";

function SMSTab() {
    const { getRequest,postRequest, putRequest , error } = useAPI();

  const [searchValue, setSearchValue] = useState({
    to_number: "",
  });

  // Dropdown from options
  const fromOptions = [
    {
      label: "Whats APP Via +447480777711 (Not Working)",
      value: "whatsapp:+447480777711"
    },
      {
      label: "Via Whats APP Sandbox",
      value: "whatsapp:+14155238886"
    },
    {
      label: "SMS Via +447480777711",
      value: "+447480777711"
    },
  ];

  const [smsList, setSmsList] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [previewInfos, setPreviewInfos] = useState({
    id: "",
    to_number: "",
    from_number: "",
    text_body: "",
  });

  const [popup, setPopup] = useState({
    desc: "",
    severity: "",
  });

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPopup({
      desc: "",
      severity: "success",
    });
  };

  const querySmsList = async () => {
    try {
      const infos = await getRequest(`saveOrSendTextMessage`);

      const { data } = infos;

      setSmsList(data);

      setPopup({
        desc: "Sms List fetched successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      setPopup({
        desc: "Failed to fetch Sms list",
        severity: "error",
      });
    }
  };

  const querySmsListByTo = async (to) => {
    try {
      const infos = await getRequest(`saveOrSendTextMessage/search/${to}`);

      setSmsList(infos.data);

      setPopup({
        desc: "Infos fetched successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);

      setPopup({
        desc: "Failed to fetch infos",
        severity: "error",
      });
    }
  };

  const querySmsById = async (id) => {
    try {
      const infos = await getRequest(`saveOrSendTextMessage/${id}`);

      setPreviewInfos({
        ...infos.data,
      });

      setPopup({
        desc: "Preview infos fetched successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);

      setPopup({
        desc: "Failed to fetch preview infos",
        severity: "error",
      });
    }
  };

  const handleEdited = async (record) => {
    setIsShowModal(true);

    await querySmsById(record.id);
  };

  const handleSearchTo = async () => {
    await querySmsListByTo(searchValue.to_number);
  };

  const handleSendWhatsapp = async () => {
    console.log("handle Send Whatsapp sms/");
  }

  const handleSubmit = async () => {
    try {
      if (!previewInfos?.id) {
        await postRequest("saveOrSendTextMessage", {
          ...previewInfos,
        });

        setPopup({
          desc: "Successfully created",
          severity: "success",
        });
      } else {
        await putRequest(`saveOrSendTextMessage/${previewInfos.id}`, {
          ...previewInfos,
        });

        setPopup({
          desc: "Successfully updated",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      setPopup({
        desc: "Failed to create or update",
        severity: "error",
      });
    }

    setPreviewInfos({
      id: "",
      to_number: "",
      from_number: "",
      text_body: "",
    });
  };

  useEffect(() => {
    querySmsList();
  }, []);

  const dataConf = [
    ...tableHeaders,
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_text, record) => {
        return (
          <div>
            <Button
              sx={{
                minWidth: 40,
                width: 40,
                height: 40,
                color: "#fff",
                marginRight: 2,
                "& > span": {
                  width: 40,
                },
              }}
              variant="contained"
              type="submit"
              onClick={() => handleEdited(record)}
            >
              <Pageview />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns = [
         { Header: "TO", accessor: "to_number", align: "center" },
         { Header: "From", accessor: "from_number", align: "center" },
         { Header: "Text Body", accessor: "text_body", align: "center" },
         { Header: "Status", accessor: "status", align: "left" },
         { Header: "Direction", accessor: "direction", align: "left",
            Cell: ({ value }) => (
                 (!!value ? value : "--")
             )
         },

         {
           Header: "Action",
           accessor: "id",
           align: "left",
           Cell: ({ row }) => {
             return (
               <div>
                 <Button
                   sx={{
                     minWidth: 40,
                     width: 40,
                     height: 40,
                     color: "#fff",
                     marginRight: 2,
                     "& > span": {
                       width: 40,
                     },
                   }}
                   variant="contained"
                   type="submit"
                   onClick={() => handleEdited(row.original)}
                 >
                   <Pageview />
                 </Button>
               </div>
             );
           },
         },
         { Header: "Created Time", accessor: "createdAt", align: "center" ,
            Cell: ({ value }) => (
                <span>{formatDistanceToNow(new Date(value), { addSuffix: true })}</span>
            )
        },
        { Header: "Updated Time", accessor: "updatedAt", align: "center" ,
            Cell: ({ value }) => (
                <span>{formatDistanceToNow(new Date(value), { addSuffix: true })}</span>
            )
        },
      ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid pt={6} pb={3} container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography
              variant="h6"
              color="#fff"
              display={"flex"}
              justifyContent={"center"}
              sx={{
                fontSize: 22,
              }}
            >
              SMS Tab
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid item xs={7} py={3} >
          <form onSubmit={(e) => e.preventDefault()}>
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {/*<TextField
                id="search"
                label="Search"
                autoFocus
                placeholder="Search To"
                sx={{
                  m: 1,
                  width: "45%",
                }}
                onChange={(event) => {
                  setSearchValue({
                    ...searchValue,
                    to_number: event.target.value,
                  });
                }}
              />

              <Button
                sx={{
                  minWidth: 40,
                  width: 40,
                  height: 40,
                  color: "#fff",
                  marginRight: 2,
                  "& > span": {
                    width: 40,
                  },
                }}
                variant="contained"
                onClick={handleSearchTo}
              >
                <Search />
              </Button>*/}

              <Button
                sx={{
                  width: 150,
                  height: 40,
                  color: "#fff",
                  "& > span": {
                    width: 40,
                  },
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setPreviewInfos({
                    id: "",
                    to_number: "",
                    from_number: "",
                    text_body: "",
                  });
                  setIsShowModal(true);
                }}
              >
               {/* <Edit />*/}
               Send SMS
              </Button>
            </FormControl>
          </form>
        </Grid>

        {/*<TableComponent headers={dataConf} data={smsList} />*/}

       <DataTable
          table={{ columns: columns, rows: smsList }}
          isSorted={true}
          canSearch={true}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </Grid>

      <ModalComponent
        open={isShowModal}
        onClose={() => setIsShowModal(false)}
        onSubmit={handleSubmit}
        submitText="Save and Send Text"

      >
        <Grid container spacing={2} paddingTop={4}>
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                marginLeft: 4,
              }}
            >
              <Typography>To:</Typography>
              <TextField
                label="To"
                value={previewInfos.to_number}
                onChange={(e) =>
                  setPreviewInfos((values) => ({
                    ...values,
                    to_number: e.target.value,
                  }))
                }
              />
              {/*<Typography>From:</Typography>
              <TextField
                label="From"
                value={previewInfos.from_number}
                onChange={(e) =>
                  setPreviewInfos((values) => ({
                    ...values,
                    from_number: e.target.value,
                  }))
                }
              />*/}
              <Typography>From:</Typography>
                <TextField
                  id="select_from"
                  select
                  label="Select From"
                  placeholder="Select From"
                  sx={{
                    height: 45,
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                    },
                  }}
                  onChange={(event) => {
                    setPreviewInfos((_data) => ({
                      ..._data,
                      from_number: event.target.value,
                    }));
                  }}
                >
                {fromOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Typography>Body:</Typography>
              <TextareaAutosize
                style={{
                  border: "1px solid #d3d3d3",
                  padding: 4,
                  fontSize: 16,
                  resize: "none",
                  borderRadius: 4,
                }}
                minRows={6}
                aria-label="Body"
                rowsMin={4}
                placeholder="Body"
                value={previewInfos.text_body}
                onChange={(e) =>
                  setPreviewInfos((values) => ({
                    ...values,
                    text_body: e.target.value,
                  }))
                }
              />
            </FormControl>
          </form>
        </Grid>
      </ModalComponent>

      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!popup.desc && (
          <Alert
            onClose={handleClose}
            severity={popup.severity}
            sx={{ width: "100%" }}
          >
            {popup.desc}
          </Alert>
        )}
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default SMSTab;
