/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import React, { useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { CKEditor } from "ckeditor4-react";
import MDAlert from "components/MDAlert";
import { RemoveRedEye } from "@mui/icons-material";
import Switch from '@mui/material/Switch';

// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {SimpleUploadAdapter} from '@ckeditor/ckeditor5-upload';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDInput from "components/MDInput";
import { Alert, Button, Snackbar } from "@mui/material";
import useAPI from "utils/useAPI";
import ModalComponent from "./components/ModalComponent";

const tb = [
  {
    name: "document",
    items: ["Source", "-", "Print", "-", "Templates"],
  },
  {
    name: "clipboard",
    items: [
      "Preview",
      "Copy",
      "Cut",
      "Paste",
      "PasteText",
      "PasteFromWord",
      "-",
      "Undo",
      "Redo",
    ],
  },
  {
    name: "editing",
    items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
  },
  { name: "links", items: ["Link", "Unlink", "Anchor"] },

  "/",
  {
    name: "paragraph",
    items: [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
      "CreateDiv",
      "-",
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "BidiLtr",
      "BidiRtl",
    ],
  },
  {
    name: "basicstyles",
    items: [
      "Bold",
      "Italic",
      "Underline",
      "Strike",
      "Subscript",
      "Superscript",
      "-",
      "CopyFormatting",
      "RemoveFormat",
    ],
  },
  { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
  { name: "colors", items: ["TextColor", "BGColor"] },
  "/",
  { name: "insert", items: ["Image", "Table", "EmojiPanel", "SpecialChar"] },
  { name: "tools", items: ["Maximize", "ShowBlocks"] },
  { name: "about", items: ["About"] },
];

const extPlugin = "emoji,autolink,uploadimage,colorbutton";

//import { Clipboard } from '@ckeditor/ckeditor5-clipboard';
const editorConfiguration = {
  toolbar: tb,
  extraPlugins: extPlugin,
};

//config = {editorConfiguration}

function EmailDraft() {
  const { getRequest,postRequest, putRequest, deleteRequest , error } = useAPI();
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [showAlert, setShowAlert] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isCsvUploaded, setIsCsvUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [popup, setPopup] = useState({
    desc: "",
    severity: "success",
  });

  /*    let emailTo = 'kritikgarg240194@gmail.com';
        let emailSubject = 'Test 11072023 ' + Date.now();
        let emailBody = '';*/

  const [emailTo, setEmailTo] = useState("kritikgarg240194@gmail.com");
  const [emailSubject, setEmailSubject] = useState(
    "Test 11072023 " + Date.now()
  );
  const [emailBody, setEmailBody] = useState("");
  const [emailAttachments, setemailAttachments] = useState("");

  const [emailAddresses, setEmailAddresses] = useState(''); // email addresses from file upload

  //const [tempContent, setTempContent] = useState('');
  const [templateList, setTemplateList] = useState([]);
  //const [isTemplateAvail, setIsTemplateAvail] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const CSVFileRef = useRef(null);
  const uploadFileRef = useRef(null);

  const handleSubmit = async () => {
    setIsShowModal(false);
  }

  const tempColumns = [

      { Header: "Name", accessor: "name", align: "center" },
      { Header: "Subject", accessor: "subject", align: "left" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      {
        accessor: "id", // Assuming 'id' is the unique identifier for your data
        align: "center",
        Cell: ({ row }) => (
          <Button
            sx={{
              color: "#fff",
            }}
            variant="contained"
            onClick={() => handleSelectClick(row.original.id)}
          >
            Select
          </Button>
        ),
      },
  ];

  /*const handleLoadContent = () => {
    setEmailBody(tempContent);

    ckeditor.current.setData(tempContent);

    console.log(CKEditor);
    console.log(ckeditor.current.setData('djhh'));

  };*/

  const getEmailTemplates = async () => {
       try {
         const infos = await getRequest(`/data/emailTemplates`);
         const { data } = infos;

         console.log('data');
         setTemplateList(data); // set result data into templateList
         console.log(templateList);

         //setIsTemplateAvail(true); // set true into isTemplateAvail
         setIsShowModal(true); // set true into isShowModal

       } catch (error) {
         console.log(error);
       }
  };

  const handleSelectClick = async (event) => {
      console.log('event');

      const selectedId = event;
      console.log(selectedId);

      const foundRecord = templateList.find(record => record.id === selectedId);
      console.log('foundRecord');
      console.log(foundRecord);

      const selectedEmailTemplate = await getRequest(`/data/emailTemplates/${foundRecord.id}`); // get email template by id
      console.log('selectedEmailTemplate');
      console.log(selectedEmailTemplate);
      console.log(selectedEmailTemplate.data.body);

      //ckeditor.current.setData(foundRecord.body);
      ckeditor.current.setData(selectedEmailTemplate.data.body);
      setEmailSubject(foundRecord.subject);
      setIsShowModal(false);
  }

  const handleSendEmail = () => {
    // Logic to send the email with the emailContent
    console.log("Sending email:");

    const htmlBody = emailBody.replace(
      "<blockquote>",
      `<blockquote style="padding-left: 20px; border-left-width: 5px; padding-right: 8px; padding: 2px 0; border-style: solid; border-color: #ccc;">`
    );

    console.log(htmlBody);

    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(htmlBody, "text/html");
    const plainText = htmlDocument.body.textContent;

    let emailContent = {
      emailText: plainText,
      emailTo: emailTo,
      subject: emailSubject,
      filesAttach: emailAttachments,
      html: htmlBody,
      emails : emailAddresses,
    };

    const people = emailTo.split(",")?.length;

    let totalUploadedEmails = [];
    if(emailAddresses !== ''){
       totalUploadedEmails = emailAddresses.trim().split(",");
    }
    console.log('totalUploadedEmails : ' + totalUploadedEmails.length);

    console.log(emailContent);

    setIsSending(true);

    fetch("/api/saveOrSendEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailContent),
    })
      .then((response) => {
        // Handle the response
        if (response.ok) {
          console.log("Email sent successfully!");
          console.log(response.json());
          setShowAlert(true);

          if(totalUploadedEmails.length == 0){
            setPopup({
              desc: `${people} Email sent.`,
              severity: "success",
            });
          }else if(totalUploadedEmails.length > 0){
            setPopup({
               desc: ` ${totalUploadedEmails.length} Email Scheduled.`,
               severity: "success",
            });
          }

          //Reset all fields after email send
          reset();

        } else {
          console.error("Failed to send email.");
          console.log(response.json());
          setPopup({
            desc: "Failed to send email.",
            severity: "error",
          });
        }

        setIsSending(false);
      })
      .catch((error) => {
        console.error("An error occurred while sending the email:", error);
        setPopup({
          desc: "An error occurred while sending the email.",
          severity: "error",
        });
      });

    setTimeout(() => {
      setIsSending(false);
    }, 10000);
  };

  const reset = () => {
      console.log('reset method invoked');

      setEmailTo('kritikgarg240194@gmail.com');
      setEmailSubject("Test 11072023 " + Date.now());
      setEmailBody(ckeditor.current.setData(''));
      setemailAttachments('');
      setEmailAddresses('');

      if(isCsvUploaded){
          console.log('CSVFileRef.current.value : ' + CSVFileRef.current.value);
          CSVFileRef.current.value = '';
      }
      if(isFileUploaded){
          console.log('uploadFileRef.current.value : ' + uploadFileRef.current.value);
          uploadFileRef.current.value = '';
      }

      setIsCsvUploaded(false);
      setIsFileUploaded(false);

  }

  // const handleMDAlert = (event) => {
  //         console.log('handleMDAlert clicked');
  //         setShowAlert(false);
  //     };

  /*    const handleChangeEmailTo = (event) => {
            console.log(event.target.value);
            emailTo = event.target.value;
        };

        const handleChangeEmailSubject = (event) => {
            console.log(event.target.value);
            emailSubject = event.target.value;
        };*/

  const handleChangeEmailTo = (event) => {
    console.log(event.target.value);
    setEmailTo(event.target.value);
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log('handleSwitch invoked: ');
      console.log('is checked : ' + event.target.checked);
      setIsChecked(event.target.checked);
      setEmailAddresses('');
      uploadFileRef.current.value = '';
  };

  const handleChangeEmailSubject = (event) => {
    console.log(event.target.value);
    setEmailSubject(event.target.value);
  };

   let ckeditor = useRef(null);

    /**
   * upload file
   */
  const handleUpload = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData();
      const fileInput = document.querySelector('input[name="files"]');
      const files = fileInput.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append("files", file);
      }

      const response = await fetch("/api/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Upload successful!");
        setPopup({
          desc: "Upload successful!",
          severity: "success",
        });
      }

      const { fileUrls } = await response.json();

      console.log("upload:", fileUrls);

      setemailAttachments(fileUrls);
      setIsCsvUploaded(true);

      //setEmailBody(body => body + fileUrls.map((url, index) => `<img alt="${index} not show" data-cke-saved-src="${url}" style="width: 50px;height: 100px;" src="${url}" />`).join(''));
    } catch (err) {
      console.error("Upload failed.");
      console.error(err);
      setPopup({
        desc: "Upload failed.",
        severity: "error",
      });
    }
  };

   const handleFileUpload = (event) => {
     const file = event.target.files[0];

     if (file) {
       const reader = new FileReader();

       reader.onload = (e) => {
         const csvContent = e.target.result;
         const emailArray = [];

         // Parse the CSV content (adjust this for your specific CSV format)
         const lines = csvContent.split('\n').filter(line => line.trim() !== ''); // Exclude empty lines
         console.log(lines);
         console.log(lines.length);

         for (let i = 1; i < lines.length; i++) {
             console.log('line :' + lines[i]);
             const columns = lines[i].split(',');
             const email = columns[0].trim(); // Adjust the column index as needed
             emailArray.push(email);
         }

         // Remove the last comma and update the state with email addresses
         setEmailAddresses(emailArray.join(', '));
         setIsCsvUploaded(true);
       };
       reader.readAsText(file);
     }
   };

  /**
   * mention
   */
  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPopup({
      desc: "",
      severity: "success",
    });
  };

  return (
    <DashboardLayout>
      {/* {
            showAlert && (
                <MDAlert color="success" dismissible onClick = {handleMDAlert}>
                    Email sent successfully!
              </MDAlert>
            )
        } */}
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Draft Email
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2}  py={3}>
                <div className="App">

              <h2>Sending Mode</h2>
              <span style={{ color: isChecked ? '#343536':'gray', fontWeight: 'bold', fontSize: '16px' }}>
                              {isChecked ? "Bulk" : "Single"}
                            </span>
              <Switch onChange={handleSwitch} defaultChecked={isChecked} />
              <span style={{ color: isChecked ? 'gray':'#343536', fontWeight: 'bold', fontSize: '14px' }}>
                {isChecked ? "Switch to Single Mode" : "Switch to Bulk Mode"}

              </span>

                { isChecked &&
                   <div>
                      <h2>Upload CSV</h2>
                      <input
                          type="file"
                          accept=".csv"
                          onChange={handleFileUpload}
                          ref={CSVFileRef}
                          style={{
                            padding: '10px 15px',
                            width: '20%',
                            cursor: 'pointer',
                            backgroundColor: '#ebebeb',
                            color: 'black',
                            borderRadius: '5px',
                          }}
                      />
                      {/*<form>
                        <input type="file" accept=".csv" onChange={handleFileUpload} />
                        <textarea
                          value={emailAddresses}
                          onChange={(e) => setEmailAddresses(e.target.value)}
                          rows={3}
                          cols={30}
                          readOnly
                        />
                      </form>*/}
                   </div>
                }

                  <h2>Send email</h2>
                  { !isChecked &&
                  <div>
                    <MDInput
                      variant="outlined"
                      label="To"
                      value={emailTo}
                      onChange={handleChangeEmailTo}
                      sx={{ width: '20%' }}
                    />
                  </div>
                  }

                  { isChecked &&
                    <div>
                      <MDInput
                        multiline
                        variant="outlined"
                        label="To"
                        value={emailAddresses}
                        onChange={(e) => setEmailAddresses(e.target.value)}
                        rows={2}
                        sx={{ width: '20%' }}
                        disabled
                      />
                    </div>
                  }

                  <div  style={{ paddingTop: '12px' }}>
                    <MDInput
                      variant="outlined"
                      label="Subject"
                      value={emailSubject}
                      onChange={handleChangeEmailSubject}
                      sx={{ width: '20%' }}
                    />
                  </div>

                  <div style={{ paddingTop : '12px', paddingBottom : '12px' }}>
                    <form
                      action="/api/upload"
                      method="POST"
                      encType="multipart/form-data"
                      onSubmit={handleUpload}
                    >
                      <input type="file"
                         name="files"
                         multiple
                         ref={uploadFileRef}
                         style={{
                             padding: '10px 15px',
                             width: '20%',
                             cursor: 'pointer',
                             backgroundColor: '#ebebeb',
                             color: 'black',
                             borderRadius: '5px',
                         }}/>
                      <button
                        type="submit"
                        style={{
                          marginLeft: '8px',
                          padding: '10px 15px',
                          backgroundColor: '#4caf50',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}>
                        Upload Files
                      </button>
                    </form>

                  </div>

                    {/*<h2>Load Content into Editor</h2>
                    <div>
                        <textarea
                            value={tempContent}
                            onChange={(e) => setTempContent(e.target.value)}
                            rows={4}
                            cols={50}
                        />
                    </div>
                    <div>
                        <MDButton onClick={handleLoadContent}>Load into Editor</MDButton>
                    </div>*/}

                  <CKEditor
                    config={editorConfiguration}
                    initData={emailBody}
                    onChange={({ editor }) => {
                      // Handles native `focus` event.
                      console.log("changed");
                      //emailBody = editor.getData();
                      //console.log(editor.getData());
                      setEmailBody(editor.getData());
                    }}
                    onInstanceReady={({editor}) => {
                        ckeditor.current = editor;
                      console.log('Editor is ready!',editor);
                    }}


                  />
                </div>
              </MDBox>
              {/* <MDButton sx={{
                                marginTop: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} variant="outline" onClick={handleSendEmail} disabled={isSending}>{
                                isSending ? 'Sending' : 'SEND BUTTON'
                            }</MDButton> */}
            </Card>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button
                sx={{
                  color: "#fff",
                }}
                variant="contained"
                onClick={handleSendEmail}
                disabled={isSending}
              >
                {isSending ? "Sending" : "SEND BUTTON"}
              </Button>

              <Button
                  sx={{
                    color: "#fff",
                    margin: "0 20px",
                  }}
                  variant="contained"
                  onClick={getEmailTemplates}
                >
                  Use Templates
                </Button>
            </div>

            {/*{isTemplateAvail && (
               <MDBox pt={2}>
                   <h2>Available email templates</h2>
                       <DataTable
                        table={{ columns: tempColumns, rows: templateList }}
                        isSorted={true}
                        canSearch={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
               </MDBox>
            )}*/}

            <ModalComponent
                open={isShowModal}
                onClose={() => setIsShowModal(false)}
                onSubmit={handleSubmit}
                submitText="Close"
                title="Available Email Templates"
            >
            <MDBox pt={1}>
                   <DataTable
                    table={{ columns: tempColumns, rows: templateList }}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
            </MDBox>
          </ModalComponent>

          </Grid>
          <Grid item xs={12} display={"none"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Snackbar
        open={!!popup.desc}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={popup.severity}
          sx={{ width: "100%" }}
        >
          {popup.desc}
        </Alert>
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailDraft;