import React from "react";

const TableComponent = ({ headers, data }) => {
  return (
    <section>
      <div
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <table
          style={{
            width: "100%",
            maxHeight: 400,
            overflowY: "auto",
            borderCollapse: "collapse",
            fontSize: "0.875rem",
            fontWeight: "normal",
          }}
        >
          <thead>
            <tr
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                width: "100%",
                backgroundColor: "#d3d3d3",
                borderRadius: "5px",
                borderTop: "1px solid #bbb9b9",
                borderBottom: "1px solid #bbb9b9",
              }}
            >
              {headers.map((header) => (
                <th
                  style={{
                    padding: "0px 8px",
                    textAlign: "left",
                    fontWeight: "500",
                  }}
                  key={header.key}
                >
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid #bbb9b9",
                  position: "relative",
                  display: "table-row",
                }}
              >
                {headers.map((cell, cellIndex) => {
                  return (
                    <td key={cellIndex} style={{ padding: 8 }}>
                      {(cell.render && cell.render(row[cell.dataIndex], row)) ||
                        row[cell.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TableComponent;
