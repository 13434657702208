import { useState } from "react";
import { useNavigate } from "react-router-dom";

function useAPI() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);

    const performRequest = async (url, method, payload = null, isAccount = false) => {
        const authorization = localStorage.getItem("authorization");
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization ? `Bearer ${authorization}` : "",
        };
        const options = {
            method,
            headers,
            body: payload ? JSON.stringify(payload) : null,
        };

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${isAccount ? url : `/api/${url}`}`, options);

            if (response.status === 401) {
                localStorage.removeItem("authorization");
                alert('Go to Sign in, session invalid');
                navigate("/sign-in");
            }

            const responseData = await response.json();
            setResponseData(responseData);
            return responseData;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const getRequest = (url) => performRequest(url, "GET");
    const postRequest = (url, data, isAccount = false) => performRequest(url, "POST", data, isAccount);
    const putRequest = (url, data) => performRequest(url, "PUT", data);
    const deleteRequest = (url) => performRequest(url, "DELETE");
    const patchRequest = (url, data) => performRequest(url, "PATCH", data);

    return {
        loading,
        error,
        data: responseData,
        getRequest,
        postRequest,
        putRequest,
        deleteRequest,
        patchRequest
    };
}

export default useAPI;
